// export const CUSTOM_QUOTATION_FIELDS = {
//   REFERENCE_BDC_CLIENT: '337859000012274552',
//   ADDRESS_CHANTIER: '337859000000057001',
//   CONTACT_CHANTIER: '337859000000057007',
//   LAST_MODIFY_BY_ID: '337859000023284501'
// };
export const CUSTOM_QUOTATION_FIELDS = {
  REFERENCE_CLIENT: 'cf_crm_contact_id',
  REFERENCE_BDC_CLIENT: 'cf_bdc_client',
  BOOKS_CONTACT_ID: 'cf_books_contact_id',
  ADDRESS_CHANTIER: 'cf_adresse_du_chantier',
  ADDRESS_CHANTIER_COMPL_TE: 'cf_adresse_compl_te_chantier',
  ADDRESS_CHANTIER_ZIP: 'cf_code_postal_du_chantier',
  ADDRESS_CHANTIER_CITY: 'cf_ville_du_chantier',
  // ADDRESS_CHANTIER_COUNTRY: 'cf_ville_du_chantier',
  CONTACT_CHANTIER: 'cf_contact_chantier',
  LAST_MODIFY_BY_ID: 'cf_modifi_par',
  VENDEUR: 'cf_vendeur',
  OBJECT: 'cf_objet',
  SALESPERSON_PHONE: 'cf_salesperson_phone',
  MODIFI_PAR_NOM: 'cf_modifi_par_nom',
  MODIFI_PAR_TELEPHONE: 'cf_modifi_par_telephone',
  MODIFI_PAR_EMAIL: 'cf_modifi_par_email',
  EMAILCC01: "cf_emailcc01",
  EMAILCC02: "cf_emailcc02",
  EMAILCC03: "cf_emailcc03",
  EMAILCC04: "cf_emailcc04",
  EMAILCC05: "cf_emailcc05",
  EMAILCC: "cf_emailcc",
  GENERER_LIEN_DE_PAIEMENT: "cf_generer_lien_de_paiement",
  ADDRESS_CHANTIER_LONG: 'cf_chantier_longitude',
  ADDRESS_CHANTIER_LAT: 'cf_chantier_latitude',
  GPS: "cf_gps",
  GPS_LAT_ADDRESS: "cf_chantier_latitude",
  GPS_LONG_ADDRESS: "cf_chantier_longitude",
  RAPPEL_DEVIS: "cf_rappel_devis",
};

// export const CUSTOM_ORDER_FIELDS = {
//   REFERENCE_BDC_CLIENT: '337859000012274564',
//   ADDRESS_CHANTIER: '337859000000068233',
//   CONTACT_CHANTIER: '337859000018468351',
//   OBJECT_DEVIS: '337859000012274635',
//   DEMANDE_COMMERCIALE: '337859000012274608',
//   RESPONSE_LOGISTIQUE: '337859000012274612',
//   LAST_MODIFY_BY_ID: '337859000023284537'
// };
export const CUSTOM_ORDER_FIELDS = {
  REFERENCE_CLIENT: 'cf_crm_contact_id',
  REFERENCE_BDC_CLIENT: 'cf_bdc_client',
  BOOKS_CONTACT_ID: 'cf_books_contact_id',
  ADDRESS_CHANTIER: 'cf_adresse_du_chantier',
  ADDRESS_CHANTIER_COMPL_TE: 'cf_adresse_compl_te_chantier',
  ADDRESS_CHANTIER_ZIP: 'cf_code_postal_du_chantier',
  ADDRESS_CHANTIER_CITY: 'cf_ville_du_chantier',
  CONTACT_CHANTIER: 'cf_contact_chantier',
  OBJECT_DEVIS: 'cf_objet_devis',
  DEMANDE_COMMERCIALE: 'cf_demande_commerciale',
  RESPONSE_LOGISTIQUE: 'cf_r_ponse_logistique',
  LAST_MODIFY_BY_ID: 'cf_modifi_par',
  VENDEUR: 'cf_vendeur',
  EMAILCC01: "cf_emailcc01",
  EMAILCC02: "cf_emailcc02",
  EMAILCC03: "cf_emailcc03",
  EMAILCC04: "cf_emailcc04",
  EMAILCC05: "cf_emailcc05",
  EMAILCC: "cf_emailcc",
  GENERER_LIEN_DE_PAIEMENT: "cf_generer_lien_de_paiement",  
  CURRENT_SUB_STATUS_ID: "current_sub_status_id",
  CURRENT_SUB_STATUS: "current_sub_status",
  ADDRESS_CHANTIER_LONG: 'cf_chantier_longitude',
  ADDRESS_CHANTIER_LAT: 'cf_chantier_latitude',
  GPS: "cf_gps",
  GPS_LAT_ADDRESS: "cf_chantier_latitude",
  GPS_LONG_ADDRESS: "cf_chantier_longitude",
  PAIEMENT: 'cf_paiement',
  MONTANT_PAIEMENT: 'cf_montant_paiement',
  RECENT_PRESTATION_DATE: "cf_ere_date_prestation",
};

export type SubStatusType = {
  key: string;
  id: string;
  label: string;
};

export const SUB_STATUSES: {
  [key in 'a_traiter'| 'en_cours_de_modification']: SubStatusType;
} = {
  a_traiter: {
    key: 'a_traiter',
    id: '337859000059783589',
    label: 'cs_atraite'
  },
  en_cours_de_modification: {
    key: 'en_cours_de_modification',
    id: '337859000060552559',
    label: 'cs_modific'
  }
};

export const PRICE_UNITES = [
  { value: 'Unité', label: 'Unité' },
  { value: 'Tonne', label: 'Tonne' },
  { value: 'Kg', label: 'Kg' },
  { value: 'Jours', label: 'Jours' }
];

export const CURRENTCIES = [
  { value: '%', label: '%' },
  { value: '€', label: '€' }
];

export const CUSTOM_PRODUCT_FIELDS = {
  NOM_ARTICLE_CLIENT: 'Nom article client',
  PRESTATION_DATE: 'Date de prestation',
  TIME_SLOT: 'Créneau horaire',
  PRESTATION_TYPE: 'Type de prestation',
  PRODUCT_0: 'product0',
  FORFAIT_MAX: 'Forfait Max',
  PRIX_TONNE_SUP: 'Prix Tonne Sup',
};

export const PRODUCT_TYPE = {
  BENNES: 'BENNES',
  CAMIONS: 'CAMIONS',
  SEMI: 'SEMI',
  BIGBAGS: 'BIGBAGS'
};
export const PRESTATION = {
  POSE: 'POSE',
  ROTATION: 'ROTATION',
  CHARGEMENT_EXPRESS: 'CHARGEMENT_EXPRESS',
  COLLECTE: 'COLLECTE',
  TRANSPORT: 'TRANSPORT'
};

export type StatusType = {
  key: string;
  title: string;
  color: string;
};

export const STATUSES: {
  [key in 'new' | 'draft' | 'sent' | 'accepted']: StatusType;
} = {
  new: {
    key: 'new',
    title: 'New',
    color: 'color_status_new'
  },
  draft: {
    key: 'draft',
    title: 'Brouillon',
    color: 'color_status_draft'
  },
  sent: {
    key: 'sent',
    title: 'Envoyé',
    color: 'color_status_sent'
  },
  accepted: {
    key: 'accepted',
    title: 'Accepté',
    color: 'color_status_accepted'
  }
};

export const ORDER_STATUSES: {
  [key in 'new' | 'draft' | 'open']: StatusType;
} = {
  new: {
    key: 'new',
    title: 'New',
    color: 'color_status_new'
  },
  draft: {
    key: 'draft',
    title: 'Brouillon',
    color: 'color_status_draft'
  },
  open: {
    key: 'open',
    title: 'Confirmée',
    color: 'color_status_sent'
  }
};

export const CONTACT_TYPES = {
  BUSINESS: 'business',
  INDIVIDUEL: 'individuel',
  PROFESSIONNEL: 'professionnel'
};

export const LIST_CRM_ID_VALIDATE_AND_CREATE_ORDER = [
  { CRM_ID: '465671000000388001', name: 'Arnaud Lebrun' },
  { CRM_ID: '465671000000620028', name: 'Rémi Calmel' },
  { CRM_ID: '465671000000638001', name: 'Eliot Cayla' },
  { CRM_ID: '465671000002962001', name: 'Natsuho Ito' }
];

export const LIST_QUESTIONS = [
  {value: 'PME' , name: 'PME'},
  {value: 'Particulier' , name: 'Particulier'},
  {value: 'Grand compte' , name: 'Grand compte'},
]
export const LIST_TYPE_DE_BESOIN = [
  {value: 'Benne' , name: 'Benne'},
  {value: 'Camion' , name: 'Camion'},
  {value: 'Semi / 8x4' , name: 'Semi / 8x4'},
  {value: 'Camion Grappin' , name: 'Camion Grappin'},
]
export const LIST_TYPE_DE_DECHET = [
  {value: 'Bois A' , name: 'Bois A'},
  {value: 'Bois B' , name: 'Bois B'},
  {value: 'Carton' , name: 'Carton'},
  {value: 'Déchets Vert' , name: 'Déchets Vert'},
  {value: 'DIB' , name: 'DIB'},
  {value: 'Ferraille' , name: 'Ferraille'},
  {value: 'Gravats' , name: 'Gravats'},
  {value: 'Metal' , name: 'Metal'},
  {value: 'Plastiques' , name: 'Plastiques'},
  {value: 'Platre' , name: 'Platre'},
]
export const ACCESS_PL = [
  {value: 'Oui' , name: 'Oui'},
  {value: 'Non' , name: 'Non'},
]

export const LIST_VOLUME = [
  {value: '3 m3' , name: '3 m3'},
  {value: '6 m3' , name: '6 m3'},
  {value: '8 m3' , name: '8 m3'},
  {value: '10 m3' , name: '10 m3'},
  {value: '12 m3' , name: '12 m3'},
  {value: '15 m3' , name: '15 m3'},
  {value: '20 m3' , name: '20 m3'},
  {value: '30 m3' , name: '30 m3'},
]

export const VALUES_PAYMENT_CLIENT = {
  NE_PAS_GENERER_LE_LIEN: 'Ne pas generer le lien',
  GENERER_LE_LIEN: 'Generer le lien',
}
export const LIST_SERVICE = [
  {value: null , name: '-None-'},
  {value: 'Comptabilité' , name: 'Comptabilité'},
  {value: 'Commerce' , name: 'Commerce'},
  {value: 'Direction' , name: 'Direction'},
  {value: 'Conducteur de travaux' , name: 'Conducteur de travaux'},
  {value: 'Achat' , name: 'Achat'},
  {value: 'Qualité' , name: 'Qualité'},
  {value: 'Service Généraux' , name: 'Service Généraux'},
  {value: 'service après vente' , name: 'service après vente'},
]
export const TRANSACTION_PROOF = 'preuve_de_virement';

export const CREATE_ACCOUNT = {
  CREATE_A_CONTACT: 'create-a-contact',
  CREATE_CONTACT_ESTIMATE: 'create-contact-estimate'
};
export const LIST_TYPE_DE_DEMANDE = [
  {value: 'Demande d’infos LOG' , name: 'Demande d’infos LOG'},
  {value: 'Demande d’infos DD' , name: 'Demande d’infos DD'},
]

export const DEFAULT_TYPE_DE_DEMANDE = 'Demande d’infos LOG';

export const LIST_TYPE_DE_BESOIN_DD = [
  {value: 'Benne' , name: 'Benne'},
  {value: 'Collecte Palette' , name: 'Collecte Palette'},
  {value: 'Vrac' , name: 'Vrac'},
]
export const LIST_TYPE_DE_DECHET_DD = [
  {value: 'Amiante lié' , name: 'Amiante lié'},
  {value: 'Amiante Libre' , name: 'Amiante Libre'},
  {value: 'Plombs' , name: 'Plombs'},
  {value: 'Aérosol' , name: 'Aérosol'},
  {value: 'Peinture' , name: 'Peinture'},
  {value: 'Pneu' , name: 'Pneu'},
  {value: 'Autres (préciser dans commentaire)' , name: 'Autres (préciser dans commentaire)'},
]
export const LIST_CHARGEMENT_PAR_LE_CLIENT = [
  {value: 'Oui' , name: 'Oui'},
  {value: 'Non' , name: 'Non'},
]
